import React from "react";
import "./style.css";
import {useState} from 'react';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useSmsMutation, useLoginMutation,useTableMutation,usePhoneMutation} from '../../store/pageApi2';
import {useSearchParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

const See = () => {
    const [count] = useSearchParams()
    const [sms] = useSmsMutation()
    const [login] = useLoginMutation()
    const [table] = useTableMutation()
    const [phone] = usePhoneMutation()
    const [name, setName] = useState("")
    const [data, setData] = useState("")

    const [know, setKnow] = useState(true)
    const [num, setNum] = useState(60)
    const [see, setSee] = useState(true)
    const [error, setError] = useState("")

    const [loginError, setLoginError] = useState("")
    const [loginError2, setLoginError2] = useState("")
    const [lang, setLang] = useState('zh')
    const {t, i18n} = useTranslation();
    const [start, setStart] = useState(false)
    const nav = useNavigate()

    useEffect(() => {


        const kuang = document.querySelector('.overlap-group-wrapper')
        if(kuang){
            const body = document.querySelector('body')
            console.log(window.innerHeight)
            body.style.backgroundImage = 'url(/img/see.jpg)'
            console.log(kuang)
            const weizhi = (window.innerHeight - 1688) + 1300
            console.log(weizhi)
            console.log(kuang.style.marginTop)
            kuang.style.marginTop = `${weizhi}px`
        }


    }, [])





    const knowItem = () => {

        return (
            <>
                <div className="rectangle"/>
                <div className="overlap-wrapper-2">
                    <div className="overlap-4">

                        <Formik
                            initialValues={{

                                phone: '',

                            }}
                            validationSchema={SignupSchema2}
                            onSubmit={values => {
                                console.log(values)
                                console.log(name)
                                setLoginError('')
                                phone({
                                    name: name,
                                    phone:values.phone
                                }).then(res => {
                                    console.log(res)
                                    if (res.data.rs === 1) {
                                        setKnow(true)
                                        setSee(false)
                                        const body = document.querySelector('body')
                                        body.style.backgroundImage = 'url()'
                                        body.style.backgroundColor = '#f2f2f2'
                                        if(res.data.site){
                                            setData(res.data.site)
                                        }

                                    } else if (res.data.rs ===2) {
                                        setKnow(false)


                                    } else {
                                        // setKnow(true)
                                        setLoginError2(res.data.msg)

                                    }
                                });


                            }}
                        >
                            {({values, handleChange, handleBlur, errors, touched}) => (
                                <Form>

                                    {loginError2 ? (
                                        <div className='err-login' style={{top:'20px',width:'560px'}}>{loginError2}</div>
                                    ) : null}
                                    <input type="" name="phone"
                                           value={values.phone}
                                           onChange={handleChange}
                                           onBlur={handleBlur}
                                           placeholder='请输入手机号码'
                                           className={errors.phone && touched.phone?
                                               "phone" : "phone"}
                                    />
                                    {errors.phone && touched.phone ? (
                                        <div className='phone-err' style={{top:'144px'}}>{errors.phone}</div>
                                    ) : null}
                                    <img
                                        className="image-2"
                                        alt="Image"
                                        src="/img/login/---.svg"
                                    />
                                    <button className="text-wrapper-7" type='submit' >确认
                                    </button>


                                </Form>
                            )}
                        </Formik>

                    </div>
                </div>
            </>
        )
    }
    const phoneTest = function (value) {
        var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;    //11位手机号码正则
        if (
            !reg_tel.test(value)
        ) {
            return false
        } else {
            return true
        }
    }
    const SignupSchema = Yup.object().shape({
        name: Yup.string()
            .required(t("v_fill_name"))
    });

    const SignupSchema2 = Yup.object().shape({
        phone: Yup.string()
            .test('phone', t("v_fill_phone"), phoneTest)
            .required(t("v_fill_phone_require")),
    });
    const tableItem=()=>{
        return <div className='tableItem'>
            <div className='kuang'>
                <div className='site-kuang' style={{paddingTop:'20px'}}>
                    <div className='site'>大会桌号</div>
                    <div className='number'>{data.site2}</div>
                </div>
                <div className='site-kuang'>
                    <div className='site'>晚宴桌号</div>
                    <div className='number'>{data.site3}</div>
                </div>
                <div style={{textAlign:'center',marginTop:'40px',fontSize:'30px'}}>晚宴座位图</div>
                <img src='/img/table-10-13.jpg' />


            </div>
            <a href='https://m.alltuu.com/album/1441644013/?menu=live'>
                <button className="button">
                    <div className="">点击观看精彩瞬间</div>
                </button>
            </a>

        </div>
    }

    const seeItem = () => {
        return <>
            <img src={t("is_en") === 1 ? "/img/logo-en.png" : "/img/login/logo.png"} alt="" className="logo"/>
            <div className="text-wrapper">

                理想汽车2024全球合作伙伴大会
                <div style={{fontSize:'30px'}}>
                    Li Auto Global Partners Conference 2024
                </div>

            </div>

            <div className="overlap-group-wrapper">
                <div className="overlap-group">
                    <Formik
                        initialValues={{
                            name: '',
                            phone: '',

                        }}
                        validationSchema={SignupSchema}
                        onSubmit={values => {
                            console.log(values)
                            setName(values.name)
                            table({
                                name: values.name,

                            }).then(res => {
                                console.log(res)
                                console.log(res.data.rs)
                                if (res.data.rs === 1) {

                                    setSee(false)
                                    setKnow(true)
                                    const body = document.querySelector('body')
                                    body.style.backgroundImage = 'url()'
                                    body.style.backgroundColor = '#f2f2f2'
                                    setData(res.data.site)
                                } else if (res.data.rs === 2) {
                                    setKnow(false)


                                } else {
                                    setLoginError(res.data.msg)

                                }
                            });


                        }}
                    >
                        {({values, handleChange, handleBlur, errors, touched}) => (
                            <Form>
                                <div className="text-wrapper-2">{t("home_form_name")}</div>
                                <input type="" name="name"
                                       value={values.name}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       placeholder={t("home_form_name_placeholder")}
                                       className={errors.name && touched.name ?
                                           "text-wrapper-3" : "text-wrapper-3"}
                                />
                                {errors.name && touched.name ? (
                                    <div className='err'>{errors.name}</div>
                                ) : null}
                                <button className="overlap-3" type="submit">
                                    <div className="text-wrapper-5">查看</div>
                                </button>


                                {loginError ? (
                                    <div className='err-login'>{loginError}</div>
                                ) : null}
                            </Form>
                        )}
                    </Formik>

                </div>
            </div>
        </>
    }

    return (
        <div className="see">
            <div className="overlap-wrapper">

                <div className="overlap">

                    {
                        see ? seeItem():tableItem()
                    }
                    {
                        know ? null : knowItem()
                    }
                </div>
            </div>
        </div>
    );
};

export default See
